import logo from "./logo.svg";
import "./App.scss";
// import  "dis/bootstrap/less/bootstrap.less";
import React from "react";
import { OpenRoutes, PrivateRoutes } from "./routes/router";
import auth from "./services/auth";
import Spinner from "./elements/Spinner";
import AppContext from "./contexts/AppContext";
import InactivityChecker from "elements/InactivityChecker";
import SecurityPopup from "elements/SecurityPopup";
import GeoRestriction from "elements/GeoRestriction";

function App(props) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);

  const appContext = React.useMemo(
    () => ({
      updateUserObj: async (data) => {
        // console.log("UserUpdated", data);
        await checkLoggedIn();
      },
    }),
    []
  );

  const checkLoggedIn = async () => {
    var authenticated = await auth.isAuthenticated();
    if (authenticated) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  const init = async () => {
    await checkLoggedIn();
    setIsReady(true);
  };
  React.useEffect(() => {
    init();
  }, []);

  if (isReady) {
    return (
      <AppContext.Provider value={appContext}>
        <GeoRestriction>
          <SecurityPopup>
            {!isLoggedIn && <OpenRoutes {...props} />}
            {isLoggedIn && (
              <React.Fragment>
                <InactivityChecker />
                <PrivateRoutes {...props} />
              </React.Fragment>
            )}
          </SecurityPopup>
        </GeoRestriction>
      </AppContext.Provider>
    );
  } else {
    return <Spinner />;
  }
}

export default App;
