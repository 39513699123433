import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "../services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import emailValidate from "../services/emailValidate";
import moneyFormatter from "../services/moneyFormatter";
import { Modal, Button } from "react-bootstrap";
import Spinner from "elements/Spinner";

const BuyGoldCoins = (props) => {
  // buymodal
  const [buyModal, setBuyModal] = useState(false);
  const closeBuyModal = () => {
    setBuyModal(false);
  };

  //getBuyGoldCoinPlans functionality START'S here
  const [sweepstakeProductSD, setSweepstakeProductSD] = useState([]);
  const [sweepstakeProduct, setSweepstakeProduct] = useState([]);
  const getBuyGoldCoinPlans = async () => {
    var response = await api.get("/get-buy-gold-coin-plans");
    props.updateSpinner(false);
    if (response.status === 200 && response.data) {
      setSweepstakeProductSD(response.data.sweepstakeProductSD);
      setSweepstakeProduct(response.data.sweepstakeProduct);
    }
  };

  useEffect(async () => {
    if (props.showBuyModal) {
      setBuyModal(props.showBuyModal);
      props.updateSpinner(true);
      getBuyGoldCoinPlans();
    }
  }, [props.showBuyModal]);

  const [buyPlanData, setBuyPlanData] = useState({
    planId: "",
    planPrice: "",
    openWallet: false,
    GC: 0,
    SC: 0,
  });

  useEffect(async () => {
    props.callDepositModal(buyPlanData);
  }, [buyPlanData]);

  return (
    <>
      <Modal className="backdrop_buy" size="xl" show={buyModal}>
        <Modal.Body className="buy_modal">
          <div className="buy_coins">
            <div className="buy_coin_header">
              <div className="page_title golden">Buy Fun Play Coins</div>
              <div onClick={closeBuyModal} className="modal_close">
                <i className="fal fa-times"></i>
              </div>
            </div>

            <div className="buy_coins_content">
              <div className="container">
                <div className="store__info">
                  <span className="store__not-needed">
                    It's always FREE to play our SWEEPSSTAKE COINS games. No purchase necessary to play.
                  </span>
                  {/* <hr className="store__break"></hr>
                  <span className="store__giveaway">
                    Did you know?
                    <span className="store__giveaway--green">
                      &nbsp;We've given our players over 50 MILLION FREE Sweepstake Coins?
                    </span>
                  </span> */}
                </div>
                <div className="common_headline text-center"></div>
                <br />

                <div className="offers">
                  {sweepstakeProductSD.length > 0 &&
                    sweepstakeProductSD.map((value, index) => (
                      <div className="offer">
                        <div className="offer_img">
                          <img src={require("../assets/images/icons/gold_popout.svg").default} />
                        </div>
                        <div className="offer_details">
                          <div className="offer_top">
                            <img width="23" height="23" src={require("../assets/images/icons/fun-coin.svg").default} />
                            <div className="package__gc-value">
                              <strong>GC</strong> {moneyFormatter.numberFormat(value.gc)}
                            </div>
                            <div className="package__rrp">${value.previous_price}</div>
                            <div className="package_btn">
                              <Link
                                onClick={() => {
                                  setBuyModal(false);
                                  setBuyPlanData({
                                    ...buyPlanData,
                                    planId: value.id,
                                    planPrice: value.price,
                                    openWallet: true,
                                    SC: value.sc,
                                    GC: value.gc,
                                  });
                                }}
                              >
                                Buy {moneyFormatter.balanceFormat(value.price, "USD")}
                              </Link>
                            </div>
                          </div>

                          <div className="package__sweeps">
                            <span className="package__sweeps-text">
                              <img
                                width="23"
                                height="23"
                                src={require("../assets/images/icons/sweep-coin.svg").default}
                              />
                              free<strong> SC </strong>
                              {value.sc} {value.sc_extra ? "+ " + value.sc_extra + " Extra" : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="common_headline text-center">Offers</div>
                <br />
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <div className="stores">
                      {sweepstakeProduct.length > 0 &&
                        sweepstakeProduct.map((value, index) => (
                          <div className="single_store">
                            <div className="store_top">
                              <img
                                width="23"
                                height="23"
                                src={require("../assets/images/icons/fun-coin.svg").default}
                              />
                              <div className="package__gc-value">
                                <strong>GC</strong> {moneyFormatter.numberFormat(value.gc)}
                              </div>

                              <div className="package_btn">
                                <Link
                                  onClick={() => {
                                    setBuyModal(false);
                                    setBuyPlanData({
                                      ...buyPlanData,
                                      planId: value.id,
                                      planPrice: value.price,
                                      openWallet: true,
                                      SC: value.sc + " " + (value.sc_extra ? "+ " + value.sc_extra + " Extra" : ""),
                                      GC: value.gc,
                                    });
                                  }}
                                >
                                  {value.price ? (
                                    <>Buy {moneyFormatter.balanceFormat(value.price, "USD")}</>
                                  ) : (
                                    <>Claim Now</>
                                  )}
                                </Link>
                              </div>
                            </div>

                            {value.sc > 0 && (
                              <div className="package__sweeps">
                                <span className="package__sweeps-text">
                                  <img
                                    width="23"
                                    height="23"
                                    src={require("../assets/images/icons/sweep-coin.svg").default}
                                  />
                                  free<strong> SC </strong>
                                  {value.sc} {value.sc_extra ? "+ " + value.sc_extra + " Extra" : ""}
                                </span>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BuyGoldCoins;
