import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "../services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import emailValidate from "../services/emailValidate";
import ScrollDown from "elements/ScrollDown";

const Banner = (props) => {
  // const menuOpen = props.menuOpen;
  const [isAuthenticated, setIsAuthenticated] = useState(
    auth.isAuthenticated()
  );

  return (
    <>
      {!isAuthenticated && (
        <>
          <section className="banner_area main_banner">
            {/* <img
          alt=""
          src={require("../assets/images/icons/banner-with-wheel.png").default}
        /> */}
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="banner_text_section">
                    <div className="banner_headline">
                      <span className="golden">America's</span> <br />
                      MOST LOVED SOCIAL CASINO EXPERIENCE
                    </div>

                    <div className="paragraph white">
                      <br />
                      Get ready for some serious fun and let the good times roll
                      across any device.
                    </div>
                    <br />
                    <div className="button_section">
                      <Link className="common_button" to="/register">
                        Create Account
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-8 for_position_banner">
                  <Link to="/home">
                    <img
                      src={
                        require("../assets/images/banners/slots_banner.png")
                          .default
                      }
                    />
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section class="banner d-none">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="banner_text_section">
                    <div class="banner_title">
                      AMERICA'S #1
                      <br /> SOCIAL CASINO
                      <br /> EXPERIENCE
                    </div>
                    <div class="banner_dsc">
                      Get ready for some serious fun and let the good times roll
                      across any device
                    </div>
                    <div class="banner_button_group">
                      <Link to="/register" className="common_button">
                        Create account
                      </Link>
                    </div>
                  </div>
                </div>
                <ScrollDown />
                <div class="col-md-6 ">
                  <div class="banner_img animate-waves d-md-block d-none">
                    <img
                      src={
                        require("../assets/images/banners/landing-banner.png")
                          .default
                      }
                    />

                    {/* <img
                    src={
                      require("../assets/images/banners/wild-world-casino-main.png")
                        .default
                    }
                  /> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Banner;
