import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "../services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import emailValidate from "../services/emailValidate";

const Footer = (props) => {
  // const menuOpen = props.menuOpen;
  const [showSupportSidebar, setShowSupportSidebar] = useState(false);
  useEffect(() => {
    if (showSupportSidebar === true) {
      props.showSupportSidebar(showSupportSidebar);
    }
  }, [showSupportSidebar]);

  const d = new Date();
  let year = d.getFullYear();

  return (
    <>
      <footer>
        <section class="footer">
          <div class="container">
            <div className="footerLink--box">
              <div className="row">
                <div class="brands our-Brands col-12 col-lg-6 col-md-12">
                  {/* <h4 className="section_title">Description</h4> */}
                  <img
                    style={{ height: 50, marginTop: -4 }}
                    src={require("../assets/images/icons/18-plus-logo.png").default}
                  />
                  {/* &nbsp; */}
                  <img
                    style={{ height: 70 }}
                    src={require("../assets/images/payment_methods/footer-logos/visa.png").default}
                  />
                  {/* &nbsp; */}
                  <img
                    style={{ height: 70 }}
                    src={require("../assets/images/payment_methods/footer-logos/mastercard.png").default}
                  />
                  {/* &nbsp; */}
                  <img
                    style={{ height: 45 }}
                    src={require("../assets/images/payment_methods/footer-logos/btc.png").default}
                  />
                  {/* &nbsp; */}
                  <img
                    style={{ height: 45 }}
                    src={require("../assets/images/payment_methods/footer-logos/eth.png").default}
                  />
                  {/* &nbsp; */}
                  <img
                    style={{ height: 45 }}
                    src={require("../assets/images/payment_methods/footer-logos/tron.png").default}
                  />
                  {/* &nbsp; */}
                  <img
                    style={{ height: 45 }}
                    src={require("../assets/images/payment_methods/footer-logos/bnb.png").default}
                  />
                  {/* &nbsp; */}
                  <img
                    style={{ height: 45 }}
                    src={require("../assets/images/payment_methods/footer-logos/usdc.png").default}
                  />
                  {/* &nbsp; */}
                  <br />
                  <br />
                  <p>
                    Wild World Casino Social Gaming Platform is a play-for-fun website intended for amusement purposes
                    only. Wild World Casino Social Gaming Platform does not offer “real-money gambling” or opportunity
                    to win real money based on a gameplay. Wild World Casino Social Gaming Platform is only open to
                    Eligible Participants, who are at least eighteen (18) years old or the age of majority in their
                    jurisdiction (whichever occurs later) at the time of entry. NO PURCHASE NECESSARY to enter
                    Sweepstakes. SWEEPSTAKES ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see{" "}
                    <Link to="/sweeps-rules">Sweeps Rules</Link>
                  </p>
                  {/* <a
                    className="footer_credit_logo"
                    href="https://www.patriotbankcard.com"
                  >
                    <img
                      src="https://www.patriotbankcard.com/cclogos/major-credit-card-logos-png-5.png"
                      alt="logo"
                    />
                  </a> */}
                  {/* <img width="170" src={require("../assets/images/payment_methods/PayPal-Logo.png").default} /> */}
                </div>
                <div class="quicklinks col-12 col-lg-3 col-md-6">
                  <h4 className="section_title">Quick Links</h4>
                  <ul>
                    <li className="d-none">
                      <Link target="blank">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Fan Wall
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy" target="blank">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/sweeps-rules">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Sweeps Rules
                      </Link>
                    </li>
                    <li>
                      <Link to="/responsible-social-gameplay-policy" target="blank">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Responsible Social Gameplay
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSupportSidebar(true);
                          setTimeout(function () {
                            setShowSupportSidebar(false);
                          }, 500);
                        }}
                      >
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Contact Us
                      </Link>
                    </li>
                    {/* <li>
                      <Link target="blank">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Privacy settings
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/promotions">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Promotions
                      </Link>
                    </li>
                    <li className="d-none">
                      <Link to="/tournaments">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Tournaments
                      </Link>
                    </li>
                  </ul>
                </div>

                <div class="brands socials-Link col-12 col-lg-3 col-md-6">
                  <h4 className="section_title">Socials Links</h4>
                  <ul>
                    <li>
                      <a target="_blank" href="https://www.facebook.com/profile.php?id=100077744835733">
                        <i class="fab fa-facebook-f icon"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.instagram.com/wildworldcasino">
                        <i class="fab fa-instagram icon"></i>
                      </a>
                    </li>
                    <li className="d-none">
                      <a href="#">
                        <i class="fab fa-linkedin-in icon"></i>
                      </a>
                    </li>
                    <li className="d-none">
                      <a href="#">
                        <i class="fab fa-google-plus-g icon"></i>
                      </a>
                    </li>
                  </ul>
                  <div className="Footer_logo">
                    <Link to="/">
                      <img src={require("../assets/images/icons/Wild World Casino.png").default} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="clearfix"></div> */}
            <div class="footer_description">
              <big>
                <b>Copyright © {year} Wild World Casino.</b>
              </big>
              <br />
              Wild World Casino Casino is owned and operated by IGW Wild World LLC.
              <br />
              The registered address of IGW Wild World LLC is 614 N Dupont Hwy, Ste: 210, Kent County, Dover, Delaware,
              19901, USA.
              <br />
              <br />
              <big>
                <b>Postal Alternative Method of Entry</b>
              </big>
              <br />
              Receive free Sweepstake Coins by obtaining a Postal Request Code and sending a handwritten request that
              meets the requirements specified in our Sweeps Rules to: IGW WILD WORLD LLC, WILD WORLD CASINO SWEEPSTAKES
              DEPARTMENT, PO BOX 4188 SUNNYSIDE, NY 11104. For more details see our{" "}
              <Link to="/sweeps-rules">Sweeps Rules</Link>.
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
