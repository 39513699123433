import React, { useEffect, useState } from "react";
import { Button, Alert, Collapse } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import emailValidate from "services/emailValidate";
import validator from "validator";
import Swal from "sweetalert2";
import api from "services/api";
import auth from "services/auth";
// import Spinner from "../elements/Spinner";
import AppContext from "contexts/AppContext";

import FacebookLogin from "react-facebook-login";
import AppleLogin from "react-apple-login";
// import GoogleLogin from "react-google-login";

import SocialRegister from "./SocialRegister";
import SocialLogin from "./SocialLogin";
import config from "configs/config.js";
import jwt_decode from "jwt-decode";

export default function Login(props) {
  const history = useHistory();

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  const [errors, setErrors] = useState({});
  // const params = queryString.parse(window.location.search).p;

  //Auth State
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const { updateUserObj } = React.useContext(AppContext);

  const [mainErrorMgs, setMainErrorMgs] = useState("");

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [acceptField, setAcceptField] = useState({
    email: false,
    password: false,
  });

  const loginValidateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);
    if (loginData.email < 2 || loginData.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: "Please enter your email" };
      valid = false;
    }
    if (loginData.password < 2 || loginData.password.length > 250) {
      errorsTmp = { ...errorsTmp, password: "Please enter password" };
      valid = false;
    }
    if (!emailValidate(loginData.email)) {
      errorsTmp = { ...errorsTmp, email: "Your email not valid" };
      valid = false;
    }
    setErrors(errorsTmp);
    return valid;
  };

  const updateLoginForm = (field, value) => {
    setLoginData({ ...loginData, [field]: value });
  };

  const loginInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    if (field == "email") {
      if (emailValidate(value)) {
        setAcceptField({ ...acceptField, email: true });
        setErrors({ ...errors, email: "" });
      } else {
        setAcceptField({ ...acceptField, email: false });
        setErrors({ ...errors, email: "Your email not valid" });
      }
    }
    if (field == "password") {
      if (
        validator.isStrongPassword(value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setAcceptField({ ...acceptField, password: true });
        setErrors({ ...errors, password: "" });
      } else {
        setAcceptField({ ...acceptField, password: false });
        setErrors({
          ...errors,
          password:
            "Password at least 8-30 characters,  one number, one special character, one lowercase and one upercase. Don't use email address or username.",
        });
      }
    }

    updateLoginForm(field, value);
  };

  const loginActionBAK = () => {
    var valid = loginValidateForm();
    if (valid) {
      Swal.fire({
        title: "Login Success!",
        icon: "success",
      });

      history.push("/home");
    }
  };

  const loginAction = async (ev) => {
    ev.preventDefault();
    var valid = loginValidateForm();
    setMainErrorMgs("");
    if (valid) {
      props.updateSpinner(true);
      var response = await api.post("/login", loginData);
      // console.log(response);
      props.updateSpinner(false);
      if (response.status == 200) {
        await auth.login(response.data.user);
        await updateUserObj(response.data.user);
        // setRegistrationSuccessModal(true);
        // setShowLoginModal(false);
        // setOpenModal(false);
        setIsAuthenticated(auth.isAuthenticated);
        if (response.data.daily_sc?.sc > 0) {
          Swal.fire({
            title: "Daily Bonus!",
            html:
              "<b>Congratulations!!</b><br /><p>You are eligible for SC" +
              response.data.daily_sc?.sc +
              " and GC" +
              response.data.daily_sc?.gc +
              ".<br />Claim your bonus now.",
            icon: "success",
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Claim Bonus!',
            confirmButtonAriaLabel: "Claim Bonus!",
            cancelButtonText: "Skip",
            cancelButtonAriaLabel: "Skip Bonus",
          }).then(async (result) => {
            if (result.isConfirmed) {
              props.updateSpinner(true);
              var response = await api.get("/rewards/daily-sc/claim");
              props.updateSpinner(false);
              if (response.status == 200) {
                Swal.fire("Congratulations!", "Your daily bonus is claimed", "success");
              }
            }
          });
        }
      } else {
        Swal.fire({
          title: "Warning!",
          text: response.data.message,
          icon: "warning",
        });
      }
    }
  };

  //social functionality
  const [showSocialRegTerms, setShowSocialRegTerms] = useState(false);
  const [socialRegisterData, setSocialRegisterData] = useState({
    name: "",
    email: "",
    socialId: "",
    provider: "",
    agreeToTerms: 0,
    acceptCountryLimitation: 0,
  });

  const responseFacebook = (response) => {
    // console.log(response);
    setSocialRegisterData({
      name: response.name,
      email: response.email,
      socialId: response.userID,
      provider: "Facebook",
      agreeToTerms: 0,
      acceptCountryLimitation: 0,
    });
    // props.updateSpinner(false);
    // console.log("socialRegisterData", socialRegisterData);
  };

  const componentClicked = (response) => {
    // console.log(response);
    props.updateSpinner(true);
  };

  const successResponseGoogle = (response) => {
    setSocialRegisterData({
      name: response.profileObj.name,
      email: response.profileObj.email,
      socialId: response.profileObj.googleID,
      provider: "Google",
      agreeToTerms: 0,
      acceptCountryLimitation: 0,
    });
    console.log("successResponseGoogle", response);
    props.updateSpinner(false);
  };

  const failureResponseGoogle = (response) => {
    console.log("failureResponseGoogle", response);
    props.updateSpinner(false);
  };

  const [socialLoginData, setSocialLoginData] = useState({});
  const [applyLogin, setApplyLogin] = useState(false);
  const checkAvailability = async (ev) => {
    var response = await api.post("/check-social-availability", socialRegisterData);
    props.updateSpinner(false);
    // console.log(response.data);
    if (response.data.status == "Not Found") {
      setShowSocialRegTerms(true);
      // Swal.fire({
      //   title: "warning!",
      //   text: "Something went wrong. please try again.",
      //   icon: "warning",
      // });
    } else if (response.data.status == "Already Registered") {
      // setShowSocialRegTerms(true);

      setSocialLoginData({
        email: socialRegisterData.email,
        socialId: socialRegisterData.socialId,
        provider: socialRegisterData.provider,
      });
      setApplyLogin(true);
    } else if (response.data.status == "Email Already Registered") {
      // setShowSocialRegTerms(true);
      setSocialLoginData({
        email: socialRegisterData.email,
        socialId: socialRegisterData.socialId,
        provider: socialRegisterData.provider,
      });
      setApplyLogin(true);
    } else {
      // props.updateSpinner(false);
    }
  };

  useEffect(() => {
    if (socialRegisterData.provider != "") {
      checkAvailability();
    }
  }, [socialRegisterData]);

  const [initialSettings, setInitialSettings] = useState({
    facebook_login_enabled: 0,
    google_login_enabled: 0,
    apple_login_enabled: 0,
  });
  const getInitialSettings = async (ev) => {
    var response = await api.get("/initial-settings");
    setInitialSettings(response.data);
  };

  useEffect(() => {
    getInitialSettings();
  }, []);

  const [showHideLogin, setShowHideLogin] = useState(false);
  const [open, setOpen] = useState(false);

  //New google login functionality
  const responseGoogle = (response) => {
    // console.log(response);
    var userObject = jwt_decode(response.credential);
    // console.log(userObject.name);
    setSocialRegisterData({
      name: userObject.name,
      email: userObject.email,
      socialId: userObject.sub,
      provider: "Google",
      agreeToTerms: 0,
      acceptCountryLimitation: 0,
    });

    props.updateSpinner(false);
  };

  useEffect(() => {
    console.log("alert", "yes");
    if (window.google) {
      /* global google*/
      window.google.accounts.id.initialize({
        client_id: "477163522509-gj1eq4tt5oj0vq2hut66spsvhgd51oq2.apps.googleusercontent.com",
        callback: responseGoogle,
      });

      window.google.accounts.id.renderButton(document.getElementById("googleLogin"), {
        theme: "outline",
        type: "icon",
        shape: "circle",
      });
    }
  }, [props, window.google]);

  return (
    <>
      {applyLogin && <SocialLogin loginData={socialLoginData} />}

      {showSocialRegTerms && <SocialRegister socialRegisterData={socialRegisterData} />}

      {!showSocialRegTerms && (
        <div className="login_page">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <div className="login_headline">
                  <span className="golden">WELCOME</span> <br />
                  BACK.
                </div>
                <br />
                <div className="paragraph white">
                  Get ready for some serious fun and <br />
                  let the good times roll across any device!
                </div>
                <br />
                <br />
                <br />
                <Link className="common_button uppercase" to="/register">
                  Create Account
                </Link>
              </div>
              <div className="col-md-6">
                <div className="register no_padding col-md-9">
                  <div className="form_area login_area">
                    <div className="form_title">LOGIN</div>

                    <br />
                    <div className="indicatior"></div>
                    <br />
                    <form className={"loginFormArea  "} onSubmit={loginAction}>
                      <div className="input_item">
                        <input
                          onChange={loginInputChange}
                          type="email"
                          name="email"
                          className={
                            acceptField.email ? "form-control name_value border_success" : "form-control name_value"
                          }
                          placeholder="Your email"
                          value={loginData.email}
                        />
                        <div className="error_msg">{errors.email}</div>
                      </div>
                      <div className="input_item">
                        <input
                          onChange={loginInputChange}
                          type="password"
                          name="password"
                          className={
                            acceptField.password ? "form-control name_value border_success" : "form-control name_value"
                          }
                          placeholder="Your password"
                          value={loginData.password}
                        />
                        <div className="error_msg">{errors.password}</div>
                      </div>

                      <div className="login_button">
                        <Button type="submit" className="login_btn gradiant">
                          Login
                        </Button>
                      </div>
                    </form>
                    <br />
                    <div className="or_text_section">
                      <div className="dash"></div>
                      <div className="paragraph white no_margin">or log in with</div>
                      <div className="dash"></div>
                    </div>
                    <br />

                    <div className="social_login login_button">
                      {initialSettings.facebook_login_enabled && (
                        <>
                          <FacebookLogin
                            appId={config.FBAppId}
                            autoLoad={false}
                            fields="name,email,picture"
                            textButton=""
                            callback={responseFacebook}
                            onClick={componentClicked}
                            cssClass="login_btn facebook_btn"
                            icon="fab fa-facebook facebook_icon"
                          ></FacebookLogin>
                          {/* <i class="fa-brands fa-facebook"></i> */}
                          &nbsp;
                          {/* <div className="input_item">
                          <div className="name or_text text-center">or</div>
                        </div> */}
                        </>
                      )}

                      {initialSettings.google_login_enabled && (
                        <>
                          {/* <GoogleLogin
                            clientId={config.GoogleClientId}
                            onSuccess={successResponseGoogle}
                            onFailure={failureResponseGoogle}
                            className="login_btn google_btn"
                            icon={false}
                            cookiePolicy={"single_host_origin"}
                          >
                            <img
                              alt=""
                              width="48"
                              height="48"
                              src={
                                require("../../assets/images/icons/icons8-google.svg")
                                  .default
                              }
                            />
                          </GoogleLogin> */}
                          <div id="googleLogin" className="googleLoginButton"></div>
                        </>
                      )}
                    </div>

                    <br />
                    {/* <label for="remember">
                      <div className="paragraph white">
                        Remember me{" "}
                        <input
                          className="common_checkbox"
                          type="checkbox"
                          id="remember"
                        />
                      </div>
                    </label> */}
                    <div className="other_link_area paragraph">
                      <Link className="login_link float-left" to="/recover-password">
                        {" "}
                        Forget Password?
                      </Link>{" "}
                      <Link className="login_link float-right " to="/register">
                        Don't have an acount?
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
