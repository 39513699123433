import React, { useState, useEffect } from "react";
import ScrollTo from "elements/ScrollTo";
import auth from "services/auth";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import Header from "elements/Header";
import Footer from "elements/Footer";
import ls from "services/ls";
import AppContext from "contexts/AppContext";
import config from "configs/config";
import api from "services/api";
import Spinner from "elements/Spinner";
import Swal from "sweetalert2";
import { isMobile, isMobileByViewPort, cauculateLandScape } from "services/isMobile";
// import { useIdleTimer } from "react-idle-timer";

import SuppotrSideBar from "elements/SuppotrSideBar";
import IdleActivity from "elements/IdleActivity";

const FullLayout = ({ children, ...rest }) => {
  const { updateUserObj } = React.useContext(AppContext);
  const [openSupportSideBar, setOpenSupportSideBar] = useState();
  const showSupportSidebar = (receiveVal) => {
    setOpenSupportSideBar(receiveVal);
    setTimeout(function () {
      setOpenSupportSideBar(false);
    }, 500);
  };

  const [spinner, setSpinner] = useState(false);
  const [spinnerText, setSpinnerText] = useState(null);
  const [dummySpinner, setDummySpinner] = useState(false);

  const updateSpinner = (enabled, text) => {
    setSpinner(!!enabled);
    if (!!text && !!enabled) {
      setSpinnerText(text);
    } else {
      setSpinnerText(null);
    }
  };

  const location = useLocation();
  const [isLandscape, setIsLandscape] = useState(cauculateLandScape());
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMobileDeviceByViewPort, setIsMobileDeviceByViewPort] = useState(false);

  const showAlert = (title, message, isError, callback) => {
    Swal.fire({
      title: title,
      text: message,
      icon: isError ? "warning" : "success",
    }).then((value) => {
      if (callback) {
        callback(value);
      }
    });
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated());
  const [userDetails, setUserDetails] = useState(auth.getUser);
  const [currentCurrency, setCurrentCurrency] = useState("");

  //Get user balance functionality
  const [userBalance, setUserBalance] = useState({
    cash_balance: 0,
    bonus_balance: 0,
    total_balance: 0,
    wallets: [],
    rates: [],
  });
  const getUserBalance = async (ev) => {
    var response = await api.get("/get-balance");
    // console.log(response);
    ls.set("conversionRates", response.data.rates);
    setUserBalance(response.data);
    if (response.data.selectedBalance == "SC") {
      setCurrentCurrency("SC");
    } else if (response.data.selectedBalance == "GC") {
      setCurrentCurrency("GC");
    }
  };

  const [accountProgress, setAccountProgress] = useState(0);
  const getAccountProgress = async (ev) => {
    var response = await api.get("/account-progress");
    // console.log("accountProgress", response);
    setAccountProgress(response.data);
  };

  const [notificationCount, setNotifictionCount] = useState(0);
  const getNotificationsCount = async (ev) => {
    var response = await api.get("/player/messages-and-notifications-count");
    if (response.status === 200) {
      setNotifictionCount(response.data);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsLandscape(cauculateLandScape());
    });
    setIsMobileDevice(isMobile());
    setIsMobileDeviceByViewPort(isMobileByViewPort());

    var interval;
    if (isAuthenticated) {
      getAccountProgress();
      getUserBalance();
      setUserDetails(auth.getUser());
      getNotificationsCount();
    }

    interval = setInterval(() => {
      if (isAuthenticated) {
        getUserBalance();
        getNotificationsCount();
      }
    }, 15000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  useEffect(() => {
    setDummySpinner(true);
    setTimeout(() => {
      setDummySpinner(false);
    }, 500);
  }, [location.pathname]);

  const [displayedModal, setDisplayedModal] = useState(null);

  const [breadcrumb, setBreadcrumb] = useState([]);

  const setUsersWallet = async (currency) => {
    var response = await api.post("/set-wallet", { currency });
    // console.log(response);
    if (response.status === 200) {
      setUserBalance(response.data);
      ls.set("conversionRates", response.data.rates);
    }
  };

  const [openWalletModalTab, setOpenWalletModalTab] = useState(null);

  const [searchStr, setSearchStr] = useState(""); //Used in Header.js and Games.js
  const [providers, setProviders] = useState([]); //Used in Header.js and Games.js
  const [sortStr, setSortStr] = useState(null); //Used in Header.js and Games.js
  const [selectedProviders, setSelectedProviders] = useState([]); //Used in Header.js and Games.js
  const getProviders = async () => {
    var response = await api.get("/games/providers");
    if (response.status === 200) {
      setProviders(response.data);
    }
  };
  useEffect(() => {
    getProviders();
  }, []);

  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [categoryName, setCategoryName] = useState("Slots");
  const [referralCode, setReferralCode] = useState("");
  const passableParams = {
    referralCode,
    setReferralCode,
    showAlert,
    isMobileDevice,
    isMobileDeviceByViewPort,
    isLandscape,
    isAuthenticated,
    setIsAuthenticated,
    spinner,
    spinnerText,
    updateSpinner,
    userDetails,
    notificationCount,
    userBalance,
    getUserBalance,
    setUserBalance,
    accountProgress,
    getAccountProgress,
    displayedModal,
    setDisplayedModal,
    breadcrumb,
    setBreadcrumb,
    setUsersWallet,
    openWalletModalTab,
    setOpenWalletModalTab,
    searchStr,
    setSearchStr,
    providers,
    setProviders,
    selectedProviders,
    sortStr,
    setSortStr,
    setSelectedProviders,
    getProviders,
    showLeftSidebar,
    setShowLeftSidebar,
    setCategoryName,
    categoryName,
    currentCurrency,
    setCurrentCurrency,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  const [inPlay, setInPlay] = useState(false);
  const playPath = location.pathname.split("/");

  useEffect(() => {
    setInPlay(playPath.includes("play"));
    setIsMobileDevice(isMobile());
  }, [playPath]);

  return (
    <>
      {spinner && <Spinner text={spinnerText} />}
      {/* <IdleActivity {...passableParams} /> */}
      <div className="home">
        <Header {...passableParams} showSupportSidebar={showSupportSidebar} />

        <main>{childrenWithProps}</main>

        {(isMobileDevice && inPlay) || <Footer {...passableParams} showSupportSidebar={showSupportSidebar} />}
        <SuppotrSideBar {...passableParams} openSupportSideBar={openSupportSideBar} />
      </div>
      <ScrollTo />
    </>
  );
};

export default FullLayout;
