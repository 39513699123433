import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import emailValidate from "../../services/emailValidate";
import validator from "validator";
import Swal from "sweetalert2";
import api from "../../services/api";
import ls from "services/ls";

import FacebookLogin from "react-facebook-login";
import AppleLogin from "react-apple-login";
import GoogleLogin from "react-google-login";

import SocialRegister from "./SocialRegister";
import SocialLogin from "./SocialLogin";
import config from "configs/config.js";

export default function Register(props) {
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (params.referralCode) {
      ls.set("referralCode", params.referralCode);
      props.setReferralCode(params.referralCode);
    } else if (ls.get("referralCode")) {
      props.setReferralCode(ls.get("referralCode"));
    }
  }, [params.referralCode]);

  const [errors, setErrors] = useState({});
  // const params = queryString.parse(window.location.search).p;

  const [acceptField, setAcceptField] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    password: false,
    rePassword: false,
    dobDate: false,
    dobMonth: false,
    dobYear: false,
  });

  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    phone: "+1",
    email: "",
    password: "",
    rePassword: "",
    agreeToTerms: 0,
    acceptCountryLimitation: 0,
    dobDate: "",
    dobMonth: "",
    dobYear: "",
    referralCode: props.referralCode,
  });

  const phoneValidate = (num) => {
    if (num.toString().indexOf("+1") !== 0) {
      // if (num.toString().indexOf("+") !== 0) {
      //   var newVal = num.replace("+", "+1");
      // } else {
      //   var newVal = "+1" + num;
      // }
      // alert(newVal);
      // setRegisterData({ ...registerData, phone: newVal });
      return false;
    }
    return true;
  };

  const registerValidateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);
    if (registerData.firstName < 2 || registerData.firstName.length > 250) {
      errorsTmp = { ...errorsTmp, firstName: "Please enter first name" };
      valid = false;
    }
    if (registerData.lastName < 2 || registerData.lastName.length > 250) {
      errorsTmp = { ...errorsTmp, lastName: "Please enter last name" };
      valid = false;
    }
    if (registerData.phone < 2 || registerData.phone.length > 250) {
      errorsTmp = { ...errorsTmp, phone: "Please enter your phone" };
      valid = false;
    }
    if (!phoneValidate(registerData.phone)) {
      errorsTmp = { ...errorsTmp, phone: "Your phone number is not valid" };
      valid = false;
    }
    if (registerData.email < 2 || registerData.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: "Please enter your email" };
      valid = false;
    }
    if (!emailValidate(registerData.email)) {
      errorsTmp = { ...errorsTmp, email: "Your email is not valid" };
      valid = false;
    }
    if (registerData.password < 2 || registerData.password.length > 250) {
      errorsTmp = { ...errorsTmp, password: "Please enter password" };
      valid = false;
    }

    if (!registerData.agreeToTerms) {
      errorsTmp = {
        ...errorsTmp,
        agreeToTerms: "Please read & accept terms & privacy policy",
      };
      valid = false;
    }
    if (!registerData.acceptCountryLimitation) {
      errorsTmp = {
        ...errorsTmp,
        acceptCountryLimitation: "Please read & accept country policy",
      };
      valid = false;
    }

    var dobString =
      registerData.dobYear.toString().padStart(4, "0") +
      "-" +
      registerData.dobMonth.toString().padStart(2, "0") +
      "-" +
      registerData.dobDate.toString().padStart(2, "0");

    var timestamp = Date.parse(dobString);
    console.log("dateStringdateString", dobString);
    if (isNaN(timestamp)) {
      errorsTmp = {
        ...errorsTmp,
        dateOfBirth: "Please enter a valid date of birth",
      };
      valid = false;
    } else {
      const today = new Date();
      const birthDate = new Date(dobString);
      var age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      if (age < 18) {
        errorsTmp = {
          ...errorsTmp,
          dateOfBirth: "You are too young to create an account. Please leave this website.",
        };
        valid = false;
      }
    }

    setErrors(errorsTmp);
    return valid;
  };

  const updateRegisterForm = (field, value) => {
    setRegisterData({ ...registerData, [field]: value });
  };

  useEffect(() => {
    if (props.referralCode) {
      updateRegisterForm("referralCode", props.referralCode);
    }
  }, [props.referralCode]);

  const registerInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    if (field == "agreeToTerms") {
      var checked = ev.target.checked;
      if (checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    if (field == "acceptCountryLimitation") {
      var checked = ev.target.checked;
      if (checked) {
        value = 1;
      } else {
        value = 0;
      }
    }

    if (field == "firstName") {
      if (value.length > 2) {
        setAcceptField({ ...acceptField, firstName: true });
        setErrors({ ...errors, firstName: "" });
      } else {
        setAcceptField({ ...acceptField, firstName: false });
        setErrors({ ...errors, firstName: "Please enter first name" });
      }
    }
    if (field == "lastName") {
      if (value.length > 2) {
        setAcceptField({ ...acceptField, lastName: true });
        setErrors({ ...errors, lastName: "" });
      } else {
        setAcceptField({ ...acceptField, lastName: false });
        setErrors({ ...errors, lastName: "Please enter last name" });
      }
    }
    if (field == "phone") {
      if (phoneValidate(value)) {
        setAcceptField({ ...acceptField, phone: true });
        setErrors({ ...errors, phone: "" });
      } else {
        setAcceptField({ ...acceptField, phone: false });
        setErrors({ ...errors, phone: "Your phone number is not valid" });
      }
    }
    if (field == "email") {
      if (emailValidate(value)) {
        setAcceptField({ ...acceptField, email: true });
        setErrors({ ...errors, email: "" });

        var response = checkRegDuplicateEmail(registerData.email);
        var promise = Promise.resolve(response);
        promise.then(function (val) {
          if (val == true) {
            // setActiveStep(activeStep + 1);
          } else {
            // errorsTmp = {
            //   ...errorsTmp,
            //   email: val.email,
            // };

            // valid = false;
            // setRegErrors(errorsTmp);

            setAcceptField({ ...acceptField, email: false });
            setErrors({ ...errors, email: val.email });
          }

          // console.log("PromisePromisePromise", val);
        });
      } else {
        setAcceptField({ ...acceptField, email: false });
        setErrors({ ...errors, email: "Your email not valid" });
      }
    }
    if (field == "password") {
      if (
        validator.isStrongPassword(value, {
          minLength: 12,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setAcceptField({ ...acceptField, password: true });
        setErrors({ ...errors, password: "" });
      } else {
        setAcceptField({ ...acceptField, password: false });
        setErrors({
          ...errors,
          password:
            "Your password must contain between 12 – 25 characters, incuding at least 1 Uppercase and 1 number / special character. Don't use email address or username.",
        });
      }
    }
    if (field == "rePassword") {
      var password = registerData.password;
      if (value === password) {
        setAcceptField({ ...acceptField, rePassword: true });
        setErrors({ ...errors, rePassword: "" });
      } else {
        setAcceptField({ ...acceptField, rePassword: false });
        setErrors({ ...errors, rePassword: "password doesn't match" });
      }
    }
    updateRegisterForm(field, value);
  };

  const checkRegDuplicateEmail = async () => {
    // updateRegistrationForm(field, value);
    var response = await api.post("/check-reg-duplicate-email", {
      email: registerData.email,
    });
    if (response.status == 200) {
      return true;
    } else {
      return response.data.errors;
    }
  };

  //Register functionality START'S here

  const registrationAction = async (ev) => {
    ev.preventDefault();
    var valid = registerValidateForm();
    if (valid) {
      props.updateSpinner(true);

      var dobString =
        registerData.dobYear.toString().padStart(4, "0") +
        "-" +
        registerData.dobMonth.toString().padStart(2, "0") +
        "-" +
        registerData.dobDate.toString().padStart(2, "0");
      var postData = { ...registerData, dateOfBirth: dobString };

      var response = await api.post("/light-signup", postData);
      props.updateSpinner(false);
      // console.log(response.data);
      if (response.data.status == "success") {
        Swal.fire({
          title: "Congratulations!",
          text: "Your account just created, please check your email and verify your account.",
          icon: "success",
        });
        history.push("/login");

        // setShowRegModal(false);
        // history.push("/registration-complete");
      } else {
        setErrors(response.data.errors);
        if (response.data?.message) {
          alert(response.data.message);
        }
      }
    }
  };
  //Register functionality END'S here

  //social functionality
  const [showSocialRegTerms, setShowSocialRegTerms] = useState(false);
  const [socialRegisterData, setSocialRegisterData] = useState({
    name: "",
    email: "",
    socialId: "",
    provider: "",
    agreeToTerms: 0,
    acceptCountryLimitation: 0,
  });

  const responseFacebook = (response) => {
    // console.log(response);
    setSocialRegisterData({
      name: response.name,
      email: response.email,
      socialId: response.userID,
      provider: "Facebook",
      agreeToTerms: 0,
      acceptCountryLimitation: 0,
    });
    // props.updateSpinner(false);
    // console.log("socialRegisterData", socialRegisterData);
  };

  const componentClicked = (response) => {
    // console.log(response);
    props.updateSpinner(true);
  };

  const successEesponseGoogle = (response) => {
    setSocialRegisterData({
      name: response.profileObj.name,
      email: response.profileObj.email,
      socialId: response.profileObj.googleID,
      provider: "Google",
      agreeToTerms: 0,
      acceptCountryLimitation: 0,
    });
    // props.updateSpinner(false);
  };

  const failureResponseGoogle = (response) => {
    // console.log("failureResponseGoogle", response);
    props.updateSpinner(false);
  };

  const [loginData, setLoginData] = useState({});
  const [applyLogin, setApplyLogin] = useState(false);
  const checkAvailability = async (ev) => {
    var response = await api.post("/check-social-availability", socialRegisterData);

    // console.log(response.data);
    if (response.data.status == "Not Found") {
      // setShowSocialRegTerms(true);
      Swal.fire({
        title: "warning!",
        text: "Something went wrong. please try again.",
        icon: "warning",
      });
    } else if (response.data.status == "Already Registered") {
      // setShowSocialRegTerms(true);

      setLoginData({
        email: socialRegisterData.email,
        socialId: socialRegisterData.socialId,
        provider: socialRegisterData.provider,
      });
      setApplyLogin(true);
    } else if (response.data.status == "Email Already Registered") {
      setShowSocialRegTerms(true);
      // Swal.fire({
      //   title: "warning!",
      //   text: "Email Already Registered.",
      //   icon: "warning",
      // });
    } else {
      props.updateSpinner(false);
    }
  };

  useEffect(() => {
    if (socialRegisterData.provider != "") {
      checkAvailability();
    }
  }, [socialRegisterData]);

  const [initialSettings, setInitialSettings] = useState({
    facebook_login_enabled: 0,
    google_login_enabled: 0,
    apple_login_enabled: 0,
  });
  const getInitialSettings = async (ev) => {
    var response = await api.get("/initial-settings");
    setInitialSettings(response.data);
  };

  useEffect(() => {
    getInitialSettings();
  }, []);

  return (
    <>
      {applyLogin && <SocialLogin loginData={loginData} />}

      {showSocialRegTerms && <SocialRegister socialRegisterData={socialRegisterData} />}

      {!showSocialRegTerms && (
        <div className="login_page">
          <div className="container">
            <div className="row">
              <div className="col-md-5 align-self-center">
                <div className="login_headline">
                  <span className="golden">WELCOME</span> <br />
                  TO YOU.
                </div>
                <br />
                <div className="paragraph white">
                  Get ready for some serious fun and <br />
                  let the good times roll across any device!
                </div>
                <br />
                <br />
                <br />
                {/* <Link to="/login" className="common_button uppercase">
                  Login
                </Link> */}
              </div>
              <div className="col-md-7">
                <div className="register no_padding col-md-11">
                  <div className="form_area login_area">
                    <div className="form_title">Register</div>
                    <br />
                    <div className="other_link_area paragraph">
                      <Link className="login_link " to="/login">
                        Already have an account?
                      </Link>
                    </div>

                    <br />
                    <div className="indicatior"></div>
                    <br />
                    <form onSubmit={registrationAction}>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="input_item">
                            {/* <div className="name reg_field">First Name</div> */}
                            <input
                              onChange={registerInputChange}
                              type="text"
                              name="firstName"
                              className={
                                acceptField.firstName
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="First name"
                              value={registerData.firstName}
                            />
                            <div className="error_msg">{errors?.firstName}</div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="input_item">
                            <input
                              onChange={registerInputChange}
                              type="text"
                              name="lastName"
                              className={
                                acceptField.lastName
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="Last name"
                              value={registerData.lastName}
                            />
                            <div className="error_msg">{errors?.lastName}</div>
                          </div>
                        </div>
                        <div className="col-6 col-md-6">
                          <div className="input_item">
                            <input
                              onChange={registerInputChange}
                              type="phone"
                              name="phone"
                              className={
                                acceptField.phone ? "form-control name_value border_success" : "form-control name_value"
                              }
                              placeholder="Your phone"
                              value={registerData.phone}
                            />
                            <div className="error_msg">{errors?.phone}</div>
                          </div>
                        </div>
                        <div className="col-6 col-md-6">
                          <div className="input_item">
                            {/* <div className="name reg_field">Email</div> */}
                            <input
                              onChange={registerInputChange}
                              type="email"
                              name="email"
                              className={
                                acceptField.email ? "form-control name_value border_success" : "form-control name_value"
                              }
                              placeholder="Your email"
                              value={registerData.email}
                            />
                            <div className="error_msg">{errors?.email}</div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="input_item">
                            <input
                              onChange={registerInputChange}
                              type="password"
                              name="password"
                              className={
                                acceptField.password
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="Create a password"
                              value={registerData.password}
                            />
                            <div className="error_msg">{errors?.password}</div>
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="input_item">
                            <input
                              onChange={registerInputChange}
                              type="password"
                              name="rePassword"
                              className={
                                acceptField.rePassword
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="Confirm password"
                              value={registerData.rePassword}
                            />
                            <div className="error_msg">{errors?.rePassword}</div>
                          </div>
                        </div>

                        <div className="col-3 col-md-3">
                          <div className="input_item">
                            <div className="name reg_field">Date Of Birth</div>
                            <input
                              onChange={registerInputChange}
                              type="number"
                              name="dobDate"
                              min="1"
                              max="31"
                              step={1}
                              className={
                                acceptField.dobDate
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="Birth Day"
                              value={registerData.dobDate}
                            />
                          </div>
                        </div>

                        <div className="col-3 col-md-3">
                          <div className="input_item">
                            <div className="name reg_field">&nbsp;</div>
                            <input
                              onChange={registerInputChange}
                              type="number"
                              min="1"
                              max="12"
                              step={1}
                              name="dobMonth"
                              className={
                                acceptField.dobMonth
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="Birth Month"
                              value={registerData.dobMonth}
                            />
                          </div>
                        </div>

                        <div className="col-6 col-md-6">
                          <div className="input_item">
                            <div className="name reg_field">&nbsp;</div>
                            <input
                              onChange={registerInputChange}
                              type="number"
                              min={new Date().getFullYear() - 100}
                              max={new Date().getFullYear() - 17}
                              step={-1}
                              name="dobYear"
                              className={
                                acceptField.dobYear
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="Birth Year"
                              value={registerData.dobYear}
                            />
                          </div>
                        </div>
                        <div className="error_msg">{errors?.dateOfBirth}</div>
                      </div>

                      <div className="input_item">
                        <div class="form-check">
                          <label for="register_tos-accept-checkbox" class="form-check-label">
                            <input
                              id="register_tos-accept-checkbox"
                              name="acceptCountryLimitation"
                              class="form-check-input"
                              type="checkbox"
                              onChange={registerInputChange}
                              value={registerData.acceptCountryLimitation}
                              checked={!!registerData.acceptCountryLimitation}
                            />
                            I confirm the following: I am at least 18 years old and not a resident of the state of
                            Washington, Idoha, Nevada, Hawaii or Connecticut. I do not have any existing Wild World
                            Casino accounts. I understand Wild World Casino has the right to cancel all duplicate
                            accounts and withhold prizes won with such accounts.
                          </label>
                        </div>
                        <div className="error_msg">{errors?.acceptCountryLimitation}</div>
                      </div>

                      <div className="input_item">
                        <div class="form-check">
                          <label for="register_tos-accept-checkbox" class="form-check-label">
                            <input
                              id="register_tos-accept-checkbox"
                              name="agreeToTerms"
                              class="form-check-input"
                              type="checkbox"
                              onChange={registerInputChange}
                              value={registerData.agreeToTerms}
                              checked={!!registerData.agreeToTerms}
                            />
                            I agree to the{" "}
                            <Link target="_blank" rel="noopener noreferrer" to="/terms-and-conditions">
                              Terms of Use
                            </Link>{" "}
                            and{" "}
                            <Link target="_blank" rel="noopener noreferrer" to="/privacy-policy">
                              Privacy Policy
                            </Link>{" "}
                            as well as our partner Dwolla's{" "}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.dwolla.com/legal/tos/">
                              Terms of Use
                            </a>{" "}
                            and{" "}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.dwolla.com/legal/privacy/">
                              Privacy Policy.
                            </a>
                          </label>
                        </div>
                        <div className="error_msg">{errors?.agreeToTerms}</div>
                      </div>
                      {/* <div className="input_item">
                        <div class="form-check">
                          <label
                            for="register_region-accept-checkbox"
                            class="form-check-label"
                          >
                            <input
                              onChange={registerInputChange}
                              id="register_region-accept-checkbox"
                              name="acceptCountryLimitation"
                              class="form-check-input "
                              type="checkbox"
                              value={registerData.acceptCountryLimitation}
                            />
                            I understand that if I live outside United States or
                            Canada, or within Washington State, Idaho or Quebec,
                            I cannot redeem my Sweepstake Coins due to local laws.
                          </label>
                        </div>
                        <div className="error_msg">
                          {errors?.acceptCountryLimitation}
                        </div>
                      </div> */}
                      <div className="login_button">
                        <Button type="submit" className="login_btn gradiant">
                          Create an Account
                        </Button>
                      </div>
                    </form>
                    <br />
                    <div className="or_text_section">
                      <div className="dash"></div>
                      <div className="paragraph white no_margin">OR SIGN UP WITH</div>
                      <div className="dash"></div>
                    </div>
                    <br />

                    <br />
                    <div className="social_login login_button">
                      {initialSettings.facebook_login_enabled && (
                        <>
                          <FacebookLogin
                            appId={config.FBAppId}
                            autoLoad={false}
                            fields="name,email,picture"
                            textButton=""
                            callback={responseFacebook}
                            onClick={componentClicked}
                            cssClass="login_btn facebook_btn"
                            icon="fab fa-facebook facebook_icon"
                          ></FacebookLogin>
                          {/* <i class="fa-brands fa-facebook"></i> */}
                          &nbsp;
                          {/* <div className="input_item">
                        <div className="name or_text text-center">or</div>
                      </div> */}
                        </>
                      )}

                      {initialSettings.google_login_enabled && (
                        <>
                          <GoogleLogin
                            clientId={config.GoogleClientId}
                            onSuccess={successEesponseGoogle}
                            onFailure={failureResponseGoogle}
                            className="login_btn google_btn"
                            cookiePolicy={"single_host_origin"}
                            icon={false}
                          >
                            <img
                              alt=""
                              width="40"
                              height="40"
                              src={require("../../assets/images/icons/icons8-google.svg").default}
                            />
                          </GoogleLogin>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <pre>{JSON.stringify(registerData, null, 2)}</pre>
    </>
  );
}
